$(function () {

    page.config({

        smoothScroll: true,
        navbarHeight: 0,
        disableAOSonMobile: true,

        googleAnalyticsId: 'UA-154444540-1',
        googleApiKey: 'AIzaSyBpYhOaGZHXXVvb1T9TO3INV-ENzH1JPz8',
        /* Map styles array or location of .json file */
        googleMapStyles: [
            {
                "stylers": [
                    {
                        "gamma": 0.73
                    },
                    {
                        "saturation": -100
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "gamma": 0
                    },
                    {
                        "saturation": 0
                    }
                ]
            }
        ],

        /*
        |--------------------------------------------------------------------------
        | Google reCAPTCHA
        |--------------------------------------------------------------------------
        |
        | reCAPTCHA protects you against spam and other types of automated abuse.
        | Please signup for an API key pair and insert your `Site key` value to the
        | following variable.
        |
        | http://www.google.com/recaptcha/admin
        |
        */

        reCaptchaSiteKey: '6Ldaf0MUAAAAAHdsMv_7dND7BSTvdrE6VcQKpM-n',

        // See available languages: https://developers.google.com/recaptcha/docs/language
        reCaptchaLanguage: '',


    });

});
