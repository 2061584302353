!(function(jQ){
    
    'use strict';
    
    window.CONF = page.defaults || {}

    window.ABT = {

        accordions: {
            // onclick="javascript:console.log(this); var el = document.getElementById(this.hash.split('#')[1]); el.scrollIntoView({behavior: 'smooth'});"
            init: function() {
                this.el = null,
                this.timer = null,
                this.acco = jQ('.accordion'),
                this.tabs = jQ('a[data-toggle="collapse"]');
                
                this.tabs.on('click', null, { _this: this }, function (e) {
                    clearInterval(e.data._this.timer);
                    e.data._this.el = jQ(this);

                    e.data._this.timer = setInterval(function () {
                        if(jQuery('.accordion .collapsing').length == 0){

                            clearInterval(e.data._this.timer);
                            
                            window.scrollTo({
                                top:  e.data._this.el.offset().top - 150,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                        
                    }.bind(e.data._this), 50);

                    
                });
            }
        },

        search: {
            init: function() {
                jQ('.search-trigger').on('click', function (e) {
                    e.preventDefault();
                    jQ(this).siblings('form.search').animate({
                        width: 'toggle'
                    });
                });
            }
        },

        init: function () {
            for (var key in ABT) {
                if (typeof ABT[key].init === typeof Function) {
                    ABT[key].init();
                }
            }
        },
        
    };

    ABT.init();

})(jQuery);


$(function() {
    $('.eqHeight').matchHeight();
    // var scrollOffsetTop = 130;
    // console.log(scrollOffsetTop);

    
    $(window).on('load',function(){
        if (!$(body).hasClass("neos-backend")) {
            $('.coronaModal').modal('show');
        }
    });
});

