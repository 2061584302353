!(function (jQ) {
    "use strict";

    ABT.Utility = {
        // Validates and Parses a valid JSON string
        strToJSON: function (str, error) {
            try {
                var o = JSON.parse(str);
                if (o && typeof o === "object" && o !== null) return o;
            } catch (e) {
                if (typeof error !== "undefined" && error === true) {
                    console.log(e);
                }
            }
            return false;
        },
    };

    ABT.GoogleMaps = {
        initMap: function () {
            var opts = {
                    zoom: this.zoom,
                    zoomControl: true,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL,
                        position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                    center: this.center,
                    panControl: true,
                    scrollwheel: false,
                    rotateControl: true,
                    streetViewControl: false,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                },
                defStyles = [
                    {
                        stylers: [{ gamma: 0.73 }, { saturation: -100 }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{ gamma: 0 }, { saturation: 0 }],
                    },
                ],
                clusterOptions = {
                    imagePath: "https://[your_server]/[path]/images/m",
                    gridSize: 30,
                    zoomOnClick: false,
                    maxZoom: 10,
                };

            opts.styles = this.mapStyles ? this.mapStyles : defStyles;

            let map = (this.map = new google.maps.Map(this.wrapper[0], opts));
            let markers = this.populate();

            // this.map.addListener('idle', function () {
            //     this.resetView();
            // }.bind(this));

            jQ(window).on("resize", null, { _this: this }, function (e) {
                e.data._this.centerMap();
            });
        },

        centerMap: function () {
            google.maps.event.trigger(this.map, "resize");
            this.map.panTo(this.center);
        },

        populate: function () {
            var id,
                type,
                coords,
                content,
                defaultIcon,
                mainMarker,
                bounds = new google.maps.LatLngBounds();

            var defaultIcon = new google.maps.MarkerImage(
                this.defIcon,
                new google.maps.Size(30, 30),
                new google.maps.Point(0, 0),
                new google.maps.Point(15, 15),
                new google.maps.Size(30, 30)
            );

            var clusterIcon = new google.maps.MarkerImage(
                this.clusterIcon,
                new google.maps.Size(30, 30),
                new google.maps.Point(0, 0),
                new google.maps.Point(15, 15),
                new google.maps.Size(30, 30)
            );

            this.data = null;
            this.regions = [];
            this.markers = {
                0: {}, // all
                1: {}, // stop
                2: {}, // appr
                push: function push(element) {
                    [].push.call(this, element);
                },
            };
            this.clusterMarkers = [];

            // for (var key in this.mapPOIs) {

            // this.mainMarker  = new google.maps.LatLng(46.931977, 7.419667);

            // this.markers[0]['center'] = new google.maps.Marker({
            //     map: this.map,
            //     position: this.mainMarker,
            //     title: 'SGUV'
            // });

            this.infoWindow = new google.maps.InfoWindow({
                content: this.infoCnt,
                position: this.center,
            });

            for (var poi in this.mapPOIs) {
                this.data = this.mapPOIs[poi];

                if (!this.data || this.data["coords"] === null) continue;
                if (!this.data["coords"][0] || !this.data["coords"][1])
                    continue;

                id = this.data["identifier"];
                coords = new google.maps.LatLng(
                    this.data["coords"][0],
                    this.data["coords"][1]
                );
                content = this.generateInfoWindowContent(this.data);

                this.markers[0][id] = new google.maps.Marker({
                    map: this.map,
                    icon: defaultIcon,
                    zip: this.data["zip"],
                    apr: this.data["appr"],
                    city: this.data["city"],
                    title: this.data["title"],
                    region: this.data["region"],
                    position: coords,
                });

                this.clusterMarkers.push(this.markers[0][id]);

                google.maps.event.addListener(
                    this.markers[0][id],
                    "click",
                    function (id, coords, content) {
                        return function () {
                            this.infoWindow.setContent(content);
                            this.infoWindow.setPosition(coords);
                            this.infoWindow.open(this.map, this.markers[0][id]);

                            google.maps.event.addListener(
                                this.map,
                                "click",
                                function () {
                                    this.infoWindow.close();
                                }.bind(this)
                            );

                            this.controls.find('input[type="checkbox"]').on(
                                "click",
                                function () {
                                    this.infoWindow.close();
                                }.bind(this)
                            );
                        }.bind(this);
                    }.bind(this)(id, coords, content)
                );

                // if (this.data["stop"]) {
                //     this.markers[1][id] = this.markers[0][id];
                // }
                // if (this.data["appr"]) {
                //     this.markers[2][id] = this.markers[0][id];
                // }
                // if(this.data['region']){
                //     var region = this.data['region'];
                //     if(region){
                //         if(!this.regions[region]){
                //             this.regions[region] = [];
                //         }
                //         // this.regions[region][id] = this.markers[0][id];
                //     }
                // }
                //
                //Object.entries(add).forEach(([key,value]) => { obj[key] = value })

                this.markers[0][id].setVisible(true);
            }

            this.centerMap();
            this.bindControls();
            this.wrapper.addClass("ready");

            let map = this.map;
            let markers = this.clusterMarkers;
            let clusterStyles = [
                {
                    url: this.defIcon,
                    textColor: "white",
                    textSize: 14,
                    width: 50,
                    height: 50,
                },
                {
                    url: this.defIcon,
                    textColor: "white",
                    textSize: 14,
                    width: 50,
                    height: 50,
                },
                {
                    url: this.defIcon,
                    textColor: "white",
                    textSize: 14,
                    width: 50,
                    height: 50,
                },
            ];

            let clusterOptions = {
                zoomOnClick: true,
                styles: clusterStyles,
                gridSize: 50,
                maxZoom: 15,
                minimumClusterSize: 3,
            };

            const renderer = {
                render: ({ count, position }) =>
                    new google.maps.Marker({
                        label: {
                            text: String(count),
                            color: "white",
                            fontSize: "14px",
                        },
                        position,
                        icon: clusterIcon,
                        // adjust zIndex to be above other markers
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    }),
            };

            this.cluster = new markerClusterer.MarkerClusterer({
                map,
                markers,
                renderer,
            });

            // If fthere is an need of a callback on cluster click
            // google.maps.event.addListener(
            //     markers,
            //     "clusterclick",
            //     onClusterClick
            // );

            return this.markers;
        },

        togglePOIs: function (type, subTypes, status) {
            var latLon = null,
                adjust = false,
                bounds = this.map.getBounds();

            if (!this.markers[type]) return;

            status = status === true ? true : false;

            for (var poi in this.markers[type]) {
                latLon = new google.maps.LatLng(
                    this.markers[type][poi].getPosition().lat(),
                    this.markers[type][poi].getPosition().lng()
                );

                this.markers[type][poi].setVisible(status);

                if (status == true && !bounds.contains(latLon)) {
                    adjust = true;

                    bounds.extend(latLon);
                }
            }

            if (adjust) {
                this.map.fitBounds(bounds);
                this.resetView();
            }
        },

        filterMarkers: function () {
            var type = 0,
                status = false,
                latLon = null,
                adjust = false,
                bounds = this.map.getBounds(),
                total = 0,
                counter = 0,
                newClusterMarkers = [];

            var region = this.controls.find('select[name="ctrl_region"]').val(),
                city = this.controls.find('input[name="ctrl_place"]').val(),
                zip = this.controls.find('input[name="ctrl_zip"]').val(),
                apr = this.controls.find('input[name="appr"]').prop("checked");

            this.memberRows = jQ("#mitgliederuebersicht .table tbody");

            for (var poi in this.markers[type]) {
                var a = false,
                    c = false,
                    z = false,
                    r = false,
                    latLon = new google.maps.LatLng(
                        this.markers[type][poi].getPosition().lat(),
                        this.markers[type][poi].getPosition().lng()
                    );

                if (region && region != "ALL") {
                    if (this.markers[type][poi].region == region) {
                        r = true;
                    } else {
                        r = false;
                    }
                } else if (region == "" || region == "ALL") {
                    r = true;
                } else {
                    r = false;
                }

                if (city) {
                    if (
                        this.markers[type][poi].city == city ||
                        this.markers[type][poi].city
                            .toLowerCase()
                            .indexOf(city.toLowerCase()) != -1
                    ) {
                        c = true;
                    } else {
                        c = false;
                    }
                } else {
                    c = true;
                }

                if (zip) {
                    if (this.markers[type][poi].zip == zip) {
                        z = true;
                    } else {
                        z = false;
                    }
                } else {
                    z = true;
                }

                if (apr) {
                    if (this.markers[type][poi].apr == apr) {
                        a = true;
                    } else {
                        a = false;
                    }
                } else {
                    a = true;
                }

                if (r == true && c == true && z == true && a == true) {
                    counter++;
                    this.markers[type][poi].setVisible(true);
                    newClusterMarkers.push(this.markers[type][poi]);
                    this.memberRows
                        .find('tr.row[data-id="' + poi + '"]')
                        .removeClass("hide");
                    console.log(poi);
                } else {
                    this.markers[type][poi].setVisible(false);
                    this.memberRows
                        .find('tr.row[data-id="' + poi + '"]')
                        .addClass("hide");
                }

                if (status == true && !bounds.contains(latLon)) {
                    adjust = true;
                    bounds.extend(latLon);
                }

                total++;
            }

            this.cluster.clearMarkers();
            // Add new markers to cluster
            this.cluster.addMarkers(newClusterMarkers);

            if (!counter) {
                // optionally display all (type 1) if no results
            }

            if (adjust) {
                this.map.fitBounds(bounds);
                this.resetView();
            }

            jQ(".abte-map .dyn").html(counter);
        },

        generateInfoWindowContent: function (data) {
            if (!data) return;

            var content = '<div id="content">' + '<div id="siteNotice"></div>';

            if (data["title"]) {
                content +=
                    '<h3 id="firstHeading" class="firstHeading">' +
                    data["title"] +
                    "</h3>";
            }

            content += '<div id="bodyContent">';

            if (data["address"] || data["zip"] || data["city"]) {
                content +=
                    data["address"] +
                    "<br>" +
                    data["zip"] +
                    " " +
                    data["city"] +
                    "<br><br>";
            }

            if (data["phone"] || data["email"]) {
                content += data["phone"] + "<br>" + data["email"];
            }

            return (content += "</div></div>");
        },

        bindControls: function () {
            this.controls
                .find("button")
                .on("click", null, { _this: this }, function (e) {
                    e.data._this.filterMarkers();
                });

            // this.controls.find('input[type="checkbox"]').on('click', null, { _this: this }, function (e) {
            //     var check = jQ(this),
            //         poiSet = check.attr('data-poitype') || '',
            //         active = check.attr('data-active') == '1';

            //     // reset active checkboxses
            //     e.data._this.controls.find('input[data-active]').removeAttr('data-active').prop('checked', '');

            //     if (active) {
            //         e.data._this.togglePOIs(poiSet, null, false);
            //         check.attr('data-active', '0').prop('checked', '');
            //         //show all labels again
            //         e.data._this.togglePOIs(0, null, true);
            //     } else {
            //         //hide all labels
            //         e.data._this.togglePOIs(0, null, false);
            //         e.data._this.togglePOIs(poiSet, null, true);
            //         check.attr('data-active', '1').prop('checked', 'checked');
            //     }
            // }).prop('checked', '');
        },

        boot: function () {
            var tmr = setInterval(
                function () {
                    if (this.mapStyles != null) {
                        clearInterval(tmr);
                        this.initMap();
                    }
                }.bind(this),
                50
            );
        },

        init: function () {
            this.zoom = 8;
            this.minZoom = this.zoom;
            this.maxZoom = this.zoom - 2;
            this.center = { lat: 46.801111, lng: 8.226667 };
            this.map = null;
            this.marker = { lat: 46.801111, lng: 8.226667 };
            this.markers = null;
            this.mapStyles = null;
            this.userGeo = null;
            this.defIcon = null;
            this.clusterIcon = null;
            this.destIcon = null;
            this.dirDisplay = null;
            this.dirService = null;
            this.infoWin = true;
            this.infoCnt = "";
            this.wrapper = jQ(".abte-map .map").eq(0);
            this.controls = jQ(".abte-map .controls").eq(0);
            this.mapPOIs = ABT.Utility.strToJSON(
                jQ(".abte-map .markers").eq(0).html() || ""
            );
            this.mapStyles = CONF.googleMapStyles;

            if (!this.wrapper.length) return;

            this.defIcon = this.wrapper.attr("data-marker");
            this.clusterIcon = this.wrapper.attr("data-clusterMarker");

            !(function loadMapScript() {
                var mapsCallback = "ABT.GoogleMaps.initMap",
                    script = document.createElement("script"),
                    // script2 = document.createElement("script"),
                    // script3 = document.createElement("script"),
                    apiKey = "AIzaSyBpYhOaGZHXXVvb1T9TO3INV-ENzH1JPz8";

                script.type = "text/javascript";
                script.setAttribute("defer", "defer");
                script.setAttribute("async", "async");
                // load clusterer

                // script2.type = "text/javascript";
                // script2.setAttribute("async", "async");
                // script2.src =
                //     "https://unpkg.com/@googlemaps/markerclusterer@latest";
                // document.head.appendChild(script2);
                // script3.type = "text/javascript";
                // script3.setAttribute("async", "async");
                // script3.src =
                //     "https://polyfill.io/v3/polyfill.min.js?features=default";
                // document.head.appendChild(script3);
                // load maps
                script.src =
                    "https://maps.googleapis.com/maps/api/js?key=" +
                    apiKey +
                    "&callback=" +
                    mapsCallback;

                document.head.appendChild(script);
            })();
        },
    };

    ABT.GoogleMaps.init();
})(jQuery);
